
import { defineComponent, onMounted } from "vue";
import KTFile from "@/components/files/File.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "compliance",
  components: {
    KTFile,
  },
  data(){
    return {
      user: null
    }
  },
  mounted(){
    setCurrentPageBreadcrumbs("Compliance Documents", ["Account"]);
    this.getAccount()
  },
  methods: {
    async getAccount(){
      try{
        let response = await this.axios.get(`/users/${this.$route.params.uid}`)
        this.user = response.data.user
      }catch(e){
        console.log(e)
      }
    }
  }
});
